/* scripts.js */

var i;
var c;

// DOM
document.addEventListener( 'DOMContentLoaded', async () => {

	try {

		console.log( document.getElementsByTagName('*').length );

	} catch (error) {

		console.log( error );

	}

});


// Flyout
const toggle = document.querySelectorAll( '.site-flyout-toggle' );
const overlay = document.getElementById( 'overlay' ); 
const flyout = document.getElementById( 'flyout' );
const menuItem = flyout.querySelectorAll( '.menu-item a' );

const toggleFlyout = (e) => {

	//const elem = e.currentTarget;

	//elem.classList.toggle( 'toggled' );

	overlay.classList.toggle( 'hidden' );

	flyout.classList.toggle( 'translate-x-full' );

	//document.body.classList.toggle( 'overflow-hidden' );

	
};

for( i=0; i < toggle.length; i++ ) {

	toggle[i].addEventListener( 'click', async (e) => {

		e.preventDefault();

		toggleFlyout(e);

	});

}

for( i=0; i < menuItem.length; i++ ) {

	menuItem[i].addEventListener( 'click', async (e) => {

		toggleFlyout(e);

		flyout.classList.toggle( 'translate-x-full' );

	});

}


// Menu
const menu = document.getElementById( 'menu-primary' );
const menuLink = menu.querySelectorAll( 'a' );

const closeMenu = (e) => {	

	const flyout = document.getElementById( 'flyout' );

	flyout.classList.toggle( 'translate-x-full' );

};

for( i=0; i < menuLink.length; i++ ) {

	menuLink[i].addEventListener( 'click', (e) => {

		closeMenu();

	});

}



// Diensten
const dienstenItem = document.querySelectorAll( '.diensten-item' );

for( i=0; i < dienstenItem.length; i++ ) {

	dienstenItem[i].addEventListener( 'click', (e) => {

		e.preventDefault();

		e.currentTarget.classList.toggle( 'active' );

	});

}




// Swiper
//import Swiper, { Navigation } from 'swiper';

// configure Swiper to use modules
//Swiper.use([Navigation]);

// import Swiper styles
//import 'swiper/swiper-bundle.css';

var portfolioSwiper = new Swiper( '.swiper-container', {

	direction: 'horizontal',
	loop: false,
	freeMode: true,
	slidesPerView: 1,
	spaceBetween: 0,
	centeredSlides: false,
	navigation: {
		nextEl: '.swiper-btn-next',
		prevEl: '.swiper-btn-prev',
	},
	breakpoints: {
		640: {
			slidesPerView: 1,
			spaceBetween: 20,
		},
		768: {
			slidesPerView: 2,
			spaceBetween: 40,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 40,
		},
	}

});


// Project
/*const projectLink = document.querySelectorAll( 'a.portfolio-link' );

const getProject = (e) => {

	const id = e.currentTarget.dataset.id;
	const overlay = document.getElementById( 'overlay' );

	//fetch( `${window.js_vars.ajax}/?action=get_project&id=${id}` )

	overlay.classList.toggle( 'hidden' );

	const media = document.querySelectorAll( '.portfolio-media' );
	const title = document.querySelectorAll( '.portfolio-title' );
	const text = document.querySelectorAll( '.portfolio-text' );

	fetch( `${window.js_vars.ajax}/?action=get_project&id=${id}&nonce=${window.js_vars.nonce}`, {
		method : 'get',
		mode : 'cors',
		headers : {
			'Access-Control-Allow-Origin': '*',
			'X-WP-Header': window.js_vars.nonce
		}
	})
    .then( res => res.json() )
	.then( (res) => {

		const response = JSON.parse(res);

		console.log( response );

		

	}).catch( error => console.error(error) );

};

for( i=0; i < projectLink.length; i++ ) {

	projectLink[i].addEventListener( 'click', async (e) => {

		e.preventDefault();

		getProject(e);

	});

}*/
